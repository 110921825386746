import {
  forwardRef,
  type ComponentProps,
  type PropsWithChildren,
  type ReactNode,
} from "react";
import clsx from "clsx";

export type TextInputVariant = "primary" | "danger" | "warning";

interface Props extends ComponentProps<"input"> {
  leftContent?: ReactNode;
  rightContent?: ReactNode;
  variant?: TextInputVariant;
  wrapperClassName?: string;
}

interface WrapperProps extends PropsWithChildren {
  leftContent?: ReactNode;
  rightContent?: ReactNode;
}

function Wrapper({
  children,
  leftContent,
  rightContent,
}: Readonly<WrapperProps>) {
  return (
    <div className="relative">
      {leftContent && (
        <span className="absolute inset-y-0 left-0 flex items-center pl-4">
          {leftContent}
        </span>
      )}
      {children}
      {rightContent && (
        <div className="absolute inset-y-0 right-0 flex items-center px-4">
          {rightContent}
        </div>
      )}
    </div>
  );
}

const TextInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      variant = "primary",
      leftContent,
      rightContent,
      className: defaultClassName,
      wrapperClassName,
      ...inputProps
    },
    ref,
  ) => {
    const textInput = (
      <div
        className={clsx(
          "w-full min-w-fit rounded-md focus-within:shadow-focus",
          wrapperClassName,
        )}
      >
        <input
          className={clsx(
            "h-14 w-full rounded-md border p-4 text-lg font-normal text-neutral-text-high outline-none disabled:bg-neutral-surface-disabled disabled:text-neutral-text-disabled",
            leftContent && "pl-12",
            variant === "primary" &&
              "border-neutral-interactive bg-neutral-background focus:border-primary-interactive",
            variant === "danger" &&
              "border-danger-interactive bg-danger-surface focus:border-danger-interactive-hover",
            variant === "warning" &&
              "border-warning-interactive bg-warning-surface focus:border-warning-interactive-hover",
            defaultClassName,
          )}
          {...inputProps}
          ref={ref}
        />
      </div>
    );

    if (leftContent || rightContent) {
      return (
        <Wrapper rightContent={rightContent} leftContent={leftContent}>
          {textInput}
        </Wrapper>
      );
    }

    return textInput;
  },
);

TextInput.displayName = "TextInput";

export default TextInput;
