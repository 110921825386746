import { type ReactNode } from "react";
import { CheckIcon, CloseIcon } from "@nimbus-ds/icons";
import clsx from "clsx";
import { map } from "lodash";

import { Divider, Icon, Tag } from "~/components";
import {
  PLAN_FEATURE_CHECK_ICON_COLOR,
  PLAN_FEATURE_CROSS_ICON_COLOR,
} from "../../tailwind.config";

export interface PricingPlanFeature {
  label: string;
  enabled: boolean;
}

interface Props {
  highlight?: boolean;
  title: string;
  subtitle: string;
  description: string;
  features: PricingPlanFeature[];
  basePrice?: string;
  footer?: ReactNode;
  tag?: string;
}

export default function PricingPlanCard({
  highlight = false,
  title,
  subtitle,
  description,
  features,
  basePrice,
  footer,
  tag,
}: Props) {
  return (
    <div
      className={clsx(
        "flex flex-1 flex-col overflow-clip rounded-lg border bg-neutral-background shadow-2 marker:rounded-lg",
        highlight && "border-primary-interactive",
      )}
    >
      <div className="flex flex-row items-center justify-between p-4">
        <h1 className="text-lg font-bold leading-[normal] text-secondary-pure">
          {title}
        </h1>
        {!!tag && <Tag appearance="primary">{tag}</Tag>}
      </div>

      <div className="flex flex-col gap-4 px-4">
        <div className="flex flex-col gap-2">
          <div className="flex">
            {!!basePrice && (
              <p className="pr-2 text-2xl font-normal leading-[normal] text-neutral-text-disabled line-through">
                {basePrice}
              </p>
            )}
            <p className="text-2xl font-normal leading-[normal] text-neutral-text-high">
              {subtitle}
            </p>
          </div>

          <p className="text-lg font-normal leading-[normal] text-neutral-text-high">
            {description}
          </p>
        </div>

        <Divider />

        <div className="flex flex-col gap-2">
          {map(features, ({ label, enabled }) => {
            const iconSource = enabled ? (
              <CheckIcon color={PLAN_FEATURE_CHECK_ICON_COLOR} />
            ) : (
              <CloseIcon color={PLAN_FEATURE_CROSS_ICON_COLOR} />
            );

            return (
              <div className="flex flex-row gap-2 py-[2px]" key={label}>
                <Icon source={iconSource} />
                <label className="block text-sm font-normal text-neutral-text-disabled">
                  {label}
                </label>
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex flex-col p-4">{footer}</div>
    </div>
  );
}
