"use client";

import { Spinner } from "@nimbus-ds/components";

export default function FullscreenSpinner() {
  return (
    <main className="flex h-screen min-h-screen w-full items-center justify-center">
      <Spinner />
    </main>
  );
}
