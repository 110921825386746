import { type PropsWithChildren } from "react";
import { ChevronLeftIcon } from "@nimbus-ds/icons";

import { IconButton } from "~/components";

interface Props extends PropsWithChildren {
  title: string;
  backButtonLabel?: string;
  onBackButtonPress?: () => void;
}

function PageHeader({
  title,
  backButtonLabel,
  onBackButtonPress,
  children,
}: Readonly<Props>) {
  return (
    <div className="flex gap-4">
      {onBackButtonPress && (
        <IconButton
          onClick={onBackButtonPress}
          className="!h-10 !w-10"
          aria-label={backButtonLabel}
        >
          <ChevronLeftIcon size="medium" />
        </IconButton>
      )}

      <h1 className="flex-1 text-[32px] font-semibold leading-10 text-secondary-pure">
        {title}
      </h1>

      <div className="flex justify-end gap-4">{children}</div>
    </div>
  );
}

export default PageHeader;
