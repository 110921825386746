"use client";

import React from "react";
import { Select as NimbusSelect } from "@nimbus-ds/components";

import { NIMBUS_SELECT_ID } from "../../tailwind.config";

type Props = React.ComponentProps<typeof NimbusSelect>;

export default function Select(props: Props) {
  return <NimbusSelect {...props} data-override-id={NIMBUS_SELECT_ID} />;
}

Select.Option = NimbusSelect.Option;
Select.Group = NimbusSelect.Group;
Select.Skeleton = NimbusSelect.Skeleton;
